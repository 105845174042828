// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-kompetenzen-js": () => import("/private/var/www/bauer-drpauly-homepage/src/templates/kompetenzen.js" /* webpackChunkName: "component---src-templates-kompetenzen-js" */),
  "component---src-pages-index-js": () => import("/private/var/www/bauer-drpauly-homepage/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-js": () => import("/private/var/www/bauer-drpauly-homepage/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anwaelte-js": () => import("/private/var/www/bauer-drpauly-homepage/src/pages/anwaelte.js" /* webpackChunkName: "component---src-pages-anwaelte-js" */),
  "component---src-pages-datenschutz-js": () => import("/private/var/www/bauer-drpauly-homepage/src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("/private/var/www/bauer-drpauly-homepage/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-kontakt-js": () => import("/private/var/www/bauer-drpauly-homepage/src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */)
}

